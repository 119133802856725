<template>
  <div class="animated fadeIn">
    <div v-if="loadingAdjusmtent" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <animation />
      </div>
    </div>

    <b-form
      v-else
      @submit.prevent="save"
      novalidate
      :validated="isValid"
      autocomplete="off"
    >
      <div v-if="formData && formData.id" class="card p-3">
        <div class="row justify-content-between mb-3">
          <div class="col-auto">
            <h4 class="ml-md-0 ml-3">Documento {{formData.number}}</h4>
          </div>
          <div class="col-sm-auto col-12">
            <b-button variant="secondary" @click="openOrderAttendancesModal" class="mr-md-0 mr-3 mobile-btn-90 ml-sm-0 ml-3">
              Apontamentos
            </b-button>
          </div>
        </div>

        <div v-if="formData.processStatus" class="d-flex">
          <div class="mr-3">
            <span class="font-weight-bold">Status: </span>
            <stock-adjustment-process-status-badge
              :stockAdjustment="formData"
            ></stock-adjustment-process-status-badge>
          </div>

          <div
            v-if="
              formData.stockAdjustmentItemsLength &&
              formData.numberOfItemsAdded &&
              formData.numberOfItemsAdded < formData.stockAdjustmentItemsLength
            "
            class="d-flex align-items-center"
          >
            <span class="font-weight-bold mr-1">Progresso: </span>
            <b-progress
              :max="formData.stockAdjustmentItemsLength"
              style="min-width: 300px"
            >
              <b-progress-bar :value="formData.numberOfItemsAdded">
                {{ formData.numberOfItemsAdded }} /
                {{ formData.stockAdjustmentItemsLength }}
              </b-progress-bar>
            </b-progress>
          </div>

          <button v-if="formData.processStatusDescription && formData.processStatusDescription.length"
            type="button" class="btn btn-sm btn-outline-info ml-3" @click="showProcessStatusModal = true">
            Detalhes
          </button>
        </div>

        <div v-if="formData.movementStatus === 'L' && (!formData.processStatus || !['GP', 'GM', 'VD', 'VI'].includes(formData.processStatus))"
          class="row mt-3">
          <div v-if="saving" class="col-auto pl-5 ml-3">
            <animation/>
          </div>

          <div v-if="!saving" class="col-sm-6 col-12">
            <div class="row">
              <div v-if="!saving" class="col-xl-auto col-12">
                <button type="button" class="btn btn-lg btn-success mobile-btn-100" @click="startOrder('ENT')">
                  <i class="fa fa-sign-in"></i> Iniciar Alocação
                </button>
              </div>
              <div v-if="!saving" class="col-xl-auto col-12 mt-xl-0 mt-3">
                <button type="button" class="btn btn-lg btn-success mobile-btn-100" @click="startOrder('SAI')">
                  <i class="fa fa-sign-out"></i> Iniciar Separação
                </button>
              </div>
            </div>
          </div>

          <div v-if="!saving" class="col-sm-6 col-12 mt-sm-0 mt-3">
            <div class="row">
              <div v-if="!saving" class="col-xl-auto col-12">
                <button type="button" class="btn btn-secondary mobile-btn-100" @click="openAutoFillConfirmationModal">
                  Preencher itens com sugestão
                </button>
              </div>
              <div v-if="!saving" class="col-xl col-12 mt-xl-0 mt-3">
                <button type="button" class="btn btn-warning mobile-btn-100" @click="openAutoMovementsConfirmationModal">
                  Alocar e Separar automaticamente
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <confirmation-modal
        id="create-movements-automatically-confirmation-modal"
        msg="AVISO: Esta operação aloca e separa automaticamente os itens de lote automático do pedido e que estiverem presentes em somente um endereço. Utilize-a com cautela. Uma vez executado o primeiro movimento o ajuste não pode mais ser alterado. Tem certeza de que deseja continuar?"
        :ok="createMovementsAutomatically"
      ></confirmation-modal>

      <confirmation-modal
        id="auto-fill-confirmation-modal"
        msg="Esta operação preenche os itens do ajuste automaticamente. Para as entradas ela preenche com o endereço padrão. Para as saídas busca as sugestões de separação. Você será redirecionado para uma página onde poderá revisar as sugestões. Tem certeza de que deseja continuar?"
        :ok="confirmAutoFill"
      ></confirmation-modal>

      <b-row class="mx-md-n3 mx-1 align-items-end">
        <div class="col-xl-8 col-12">
          <label for="warehouseSelect">Selecione o Armazém do Ajuste:</label>
          <div v-if="loadingWarehouses">
            <animation />
          </div>
          <model-list-select
            v-else
            id="warehouseSelect"
            :list="warehouses"
            option-value="id"
            option-text="name"
            v-model="selectedWarehouse"
            placeholder="Armazém"
            :isDisabled="formData.movementStatus !== 'L'"
          />
          <div v-if="formData.movementStatus === 'L'" class="row mt-3">
            <div class="col-10">
              <label for="itemSelect">Selecione o Item:</label>
              <model-list-select
                id="itemSelect"
                :list="items"
                option-value="id"
                option-text="label"
                v-model="selectedItem"
                placeholder="Digite para pesquisar..."
                @searchchange="searchItem"
                :filterPredicate="alwaysTrueFilterPredicate"
              />
            </div>
            <div class="col-2">
              <div v-if="searchItemInProcess">
                <animation/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="formData.movementStatus === 'L'" class="col-xl-4 col-12 mt-xl-0 mt-3">
          <div class="row">
            <div class="col-xl-12 col-md-3 col-sm-4 col-12">
              <label>
                <span>Quantidade Atual:</span><br>
                <div v-if="loadingItemCurrentQuantity">
                  <animation/>
                </div>
                <span v-else-if="warehouseAndItemSelected">{{itemCurrentQuantity}}</span>
              </label>
            </div>

            <div class="col-xl-12 col-md-9 col-sm-8 col-12 mt-xl-3 mt-sm-0 mt-3">
              <div class="row mr-0 align-items-end">
                <div class="col pl-xl-3 pl-sm-0 pl-3">
                  <label for="quantityInput">Contagem:</label>
                  <b-input v-model="quantity" id="quantity" class="margin-right" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                </div>
                <div class="col-auto pr-0">
                  <b-button variant="outline-primary" @click.prevent="addItem"
                    :disabled="!warehouseAndItemSelected || !quantity || loadingStockAdjustmentItems || loadingItemCurrentQuantity || Number(quantity) === itemCurrentQuantity">
                    <i v-if="editIndex == null" class="fa fa-check"></i>
                    <i v-else class="fa fa-check"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>

      <div v-if="loadingStockAdjustmentItems" class="mb-5 pb-5">
        <animation />
      </div>

      <div v-if="!formData.stockAdjustmentItems.length && !loadingStockAdjustmentItems" class="mb-3 mt-3">
        <div class="row bg-light mx-0 py-3">
          <div class="col text-center">
            Nenhum Item adicionado
          </div>
        </div>
      </div>

      <div v-if="formData.stockAdjustmentItems.length && !loadingStockAdjustmentItems" class="mb-3 mt-3">
        <div v-for="(orderItem, index) in formData.stockAdjustmentItems" class="row py-3 border mx-0" :key="index"
          :class="{ 'bg-light': index % 2 != 0 }">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
            <span class="font-weight-bold">Item: </span>{{orderItem.item ? (getItemReduzidoFromRef(orderItem.item.ref) + ' - ' + orderItem.item.description) : ''}}
          </div>
          <div class="col-xl-3 col-lg-8 col-md-8 col-sm-8 col-12 mt-xl-0 mt-1">
            <div class="row">
              <div class="col-xl-12 col-4">
                <span class="font-weight-bold">Qtd. antiga: </span>{{orderItem.currentQuantity}}
              </div>
              <div class="col-xl-12 col-4 font-weight-bold">
                <span v-if="orderItem.generatedType === 'ENT'" class="text-success">
                  <span>Diferença: </span>
                  +{{Math.abs(orderItem.quantity - orderItem.currentQuantity)}}
                </span>
                <span v-else-if="orderItem.generatedType === 'SAI'" class="text-danger">
                  <span>Diferença: </span>
                  -{{Math.abs(orderItem.quantity - orderItem.currentQuantity)}}
                </span>
              </div>
              <div class="col-xl-12 col-4">
                <span class="font-weight-bold">Qtd. nova: </span>{{orderItem.quantity}}
              </div>
            </div>
          </div>
          <div v-if="formData.movementStatus === 'L' && !saving" class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-xl-0 mt-1">
            <b-button size="sm" @click.prevent="editItem(index)" :disabled="editIndex === index"
              class="mr-sm-1 mr-4 mobile-btn-45">
              <i class="fa fa-edit"></i> Editar
            </b-button>
            <b-button v-if="!formData.id || isUserManager" size="sm" variant="danger" @click.prevent="openDeleteModal(index)"
              class="ml-1 mobile-btn-45">
              <i class="fa fa-trash"></i> Remover
            </b-button>
          </div>
        </div>
      </div>

      <div class="row mx-md-n3 mx-1">
        <div class="col">
          <div v-if="!saving" class="row align-items-end">
            <div class="col">
              <button-back></button-back>
            </div>
            <div v-if="formData.id && formData.movementStatus === 'L' && isUserManager && formData.stockAdjustmentItems && formData.stockAdjustmentItems.length && (!formData.processStatus || !['GP', 'GM', 'VD', 'VI'].includes(formData.processStatus))"
              class="col-auto">
              <b-button type="button" variant="danger" size="sm" @click="openDeleteItemsWithoutMovementConfirmationModal">
                <i class="fa fa-trash"></i> Remover itens sem movimento
              </b-button>
            </div>
            <div v-if="!formData.id && isUserManager" class="col-auto">
              <b-button type="button" variant="warning" @click="showVerifyDiscrepanciesModal = true"
                :disabled="!isWarehouseSelected || loadingStockAdjustmentItems">
                Verificar discrepâncias
              </b-button>
            </div>
            <div v-if="!formData.id" class="col-auto">
              <input type="file" ref="importFileInput" style="display: none" @change="importFile" accept="application/json">
              <b-button type="button" variant="secondary" @click="$refs.importFileInput.click()">
                <i class="fa fa-upload"></i> Importar
              </b-button>
            </div>
            <div v-if="formData.movementStatus === 'L'" class="col-auto">
              <b-button type="submit" variant="primary" class="btn-lg"
                :disabled="!isWarehouseSelected || loadingStockAdjustmentItems">
                Salvar
              </b-button>
            </div>
            <div v-if="formData.movementStatus === 'O' && isWarehouseSelected" class="col-auto">
              <stock-adjustment-export-to-txt-button :stockAdjustment="formData"></stock-adjustment-export-to-txt-button>
            </div>
          </div>
        </div>
        <div v-if="saving" class="col-auto">
          <animation/>
        </div>
      </div>
    </b-form>

    <confirmation-modal
      id="delete-confirmation-modal"
      msg="Tem certeza de que deseja remover esse item do ajuste?"
      :ok="deleteItem"
    ></confirmation-modal>

    <confirmation-modal
      id="delete-items-without-movements-confirmation-modal"
      msg="Tem certeza de que deseja remover todos os lançamentos desse ajuste que não foram alocados/separados?"
      :ok="deleteItemsWithoutMovement"
    ></confirmation-modal>

    <order-attendance-fab
      v-if="formData && formData.id && formData.movementStatus === 'L'"
      :stockAdjustmentId="formData.id"
      type="AJUS"
    ></order-attendance-fab>
    <order-attendances-table-modal
      v-if="formData && formData.id"
      :title="'Apontamentos do pedido ' + (formData.number || formData.number)"
      id="order-attendances-modal"
      ref="orderAttendancesModal"
      :stockAdjustmentId="formData.id"
    ></order-attendances-table-modal>

    <b-modal
      size="lg"
      centered
      lazy
      title="Detalhes do status"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showProcessStatusModal"
      :hide-footer="true"
      id="process-status-modal"
    >
      <b-container>
        <div v-if="formData.processStatusDescription && formData.processStatusDescription.length" class="mt-3">
          <span class="font-weight-bold">Descrição: </span>
          <p>{{formData.processStatusDescription}}</p>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      size="lg"
      centered
      lazy
      title="Verificar discrepâncias"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showVerifyDiscrepanciesModal"
      id="verify-discrepancies-modal"
      @ok="verifyDiscrepancies"
    >
      <b-container>
        Esta operação verifica se há discrepâncias entre o estoque do WMS e o estoque ERP dos itens alocados no armázem {{selectedWarehouse.name}}

        <div class="row mr-0 align-items-end">
          <div class="col pl-xl-3 pl-sm-0 pl-3">
            <label for="verifyDiscrepancyLimitInput">Limite de itens à serem adicionados:</label>
            <b-input
              v-model="verifyDiscrepancyLimit"
              id="verifyDiscrepancyLimitInput"
              class="margin-right"
              type="number"
              min="1"
              max="1000"
            ></b-input>
          </div>
        </div>
      </b-container>

      <template slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button variant="secondary" @click="showVerifyDiscrepanciesModal = false">Cancelar</b-button>
        <b-button
          :disabled="loadingStockAdjustmentItems || verifyDiscrepancyLimit < 1 || verifyDiscrepancyLimit > 1000"
          variant="primary"
          @click="verifyDiscrepancies"
        >
          Confirmar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      size="sm"
      centered
      lazy
      title="Importar arquivo"
      header-bg-variant="info"
      footer-border-variant="info"
      :hide-footer="true"
      id="file-import-confirmation-modal"
    >
      <b-container>
        <label>Itens por ajuste</label>
        <select v-model="itemsPerStockAdjustment" class="form-control mb-1">
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </select>

        <small class="text-muted">OBS: Quanto maior o número de itens por ajuste, mais tempo cada ajuste levará para ser processado</small>

        <div class="row justify-content-center mt-3">
          <div class="col-auto">
            <button type="button" class="btn btn-primary" @click="confirmFileImport">
              Confirmar
            </button>
          </div>
        </div>
      </b-container>
    </b-modal>

    <order-attendance-warning
      v-if="formData && formData.id"
      :stockAdjustmentId="formData.id"
    ></order-attendance-warning>

    <b-modal id="post-delete-items-without-movements-modal" @ok="reloadPage" @hide="reloadPage" title="Aviso">
      <template slot="default">
        <p style="white-space: pre-line;">{{deleteItemsWithoutMovementsMessage}}</p>
      </template>

      <template slot="modal-footer" slot-scope="{ ok }">
        <b-button variant="primary" @click="reloadPage" class="cursor-pointer">Ok</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import CForm from './c-form'
  import ButtonBack from './c-button-back'
  import { ModelListSelect } from 'vue-search-select'
  import Animation from '@/components/loaders/animation'
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import { httpClient } from '@/service'
  import shared from '@/shared/shared';
  import OrderAttendanceFAB from '@/components/common/order-attendance-fab';
  import OrderAttendancesTableModal from '@/components/tables/order-attendances-table-modal';
  import StockAdjustmentProcessStatusBadge from "@/components/stock-adjustment-process-status-badge";
  import OrderAttendanceWarning from '@/components/common/order-attendance-warning';
  import StockAdjustmentExportToTxtButton from '@/components/stock-adjustment-export-to-txt-button';

  export default {
    name: 'form-adjustments',
    components: {
      Animation,
      CForm,
      ModelListSelect,
      ButtonBack,
      ConfirmationModal,
      'order-attendance-fab': OrderAttendanceFAB,
      OrderAttendancesTableModal,
      StockAdjustmentProcessStatusBadge,
      OrderAttendanceWarning,
      StockAdjustmentExportToTxtButton
    },

    data () {
      return {
        formData: {
          stockAdjustmentItems: []
        },
        isValid: false,
        searchItemInProcess: false,
        items: [],
        selectedItem: {},
        quantity: '',
        editIndex: null,
        deleteIndex: null,
        warehouses: [],
        selectedWarehouse: {},
        loadingWarehouses: false,
        saving: false,
        itemSearchTimeout: null,
        loadingStockAdjustmentItems: false,
        loadingAdjusmtent: false,
        itemCurrentQuantity: null,
        loadingItemCurrentQuantity: false,
        showProcessStatusModal: false,
        itemsPerStockAdjustment: 500,
        importedFile: null,
        isUserManager: false,
        authUser: null,
        deleteItemsWithoutMovementsMessage: null,
        showVerifyDiscrepanciesModal: false,
        verifyDiscrepancyLimit: 100
      }
    },

    mounted: function () {
      this.formData = {};
      this.authUser = shared.getLocalStorageObj("auth/user");
      this.findAdjustment();

      this.searchItem(null);
      this.getWarehouses();
      this.setIsUserManager();
    },

    computed: {
      isWarehouseSelected() {
        return this.selectedWarehouse
          && Object.keys(this.selectedWarehouse).length;
      },

      isItemSelected() {
        return this.selectedItem && Object.keys(this.selectedItem).length;
      },

      warehouseAndItemSelected() {
        let value = this.isWarehouseSelected && this.isItemSelected;

        if (value) {
          this.getItemCurrentQuantity();
        }

        return value;
      }
    },

    methods: {
      async searchItem (searchText) {
        if (!this.searchItemInProcess && (
          (searchText && searchText.length) || !this.selectedItem || Object.keys(this.selectedItem).length == 0
        )) {
          if (this.itemSearchTimeout) {
            clearTimeout(this.itemSearchTimeout);
          }

          this.itemSearchTimeout = setTimeout(() => {
            this.searchItemInProcess = true

            if (searchText && searchText.length) {
              httpClient
              .post(`${process.env.VUE_APP_API_URL}items/condition?page=${0}&size=${10}`, {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      },
                      {
                        field: 'sku',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      },
                      {
                        field: 'gtin',
                        conditionalOperator: 'LIKE',
                        value: searchText
                      }
                    ]
                  }
                ]
              })
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            } else {
              httpClient
              .get(`${process.env.VUE_APP_API_URL}items/?page=${0}&size=${10}`)
              .then(({ data }) => {
                this.items = this.handleData(data);
                this.setItemsLabel(searchText);
              })
              .catch((error) => {
                this.searchItemInProcess = false
                if (error.message) {
                  this.$notify.error(error)
                }
              });
            }
          }, 500);
        }
      },

      handleData(data) {
        this.searchItemInProcess = false
        this.totalRows = data.data.totalElements
        const items = data.data.content

        return items
      },

      addItem() {
        let index = this.formData.stockAdjustmentItems.length;
        let generatedType = null;

        if (this.editIndex != null) {
          index = this.editIndex;
        } else if (this.formData.stockAdjustmentItems.find(sai => sai.item && sai.item.id === this.selectedItem.id)) {
          this.$notify.textError('Esse item já foi adicionado à lista');
          return;
        }

        if (this.quantity > this.itemCurrentQuantity) {
          generatedType = 'ENT';
        } else if (this.itemCurrentQuantity > this.quantity) {
          generatedType = 'SAI';
        }

        this.formData.stockAdjustmentItems[index] = {
          quantity: Number(this.quantity.toString().replace(',', '.')),
          currentQuantity: this.itemCurrentQuantity,
          generatedType: generatedType,
          item: this.selectedItem
        };

        this.selectedItem = {};
        this.quantity = '';
        this.editIndex = null;
      },

      editItem(index) {
        this.editIndex = index;
        this.selectedItem = this.formData.stockAdjustmentItems[index].item;
        this.quantity = this.formData.stockAdjustmentItems[index].quantity;
      },

      openDeleteModal(index) {
        this.deleteIndex = index;
        this.$bvModal.show('delete-confirmation-modal');
      },

      deleteItem() {
        if (this.formData.id) {
          this.saving = true;
          httpClient.delete(`${process.env.VUE_APP_API_URL}stock-adjustment-items`, this.formData.stockAdjustmentItems[this.deleteIndex].id)
          .then(data => {
            this.saving = false;
            this.$notify.success('Excluído com sucesso');
            this.postDelete();
          }).catch(error => {
            this.saving = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao excluír')
            }
          });
        } else {
          this.postDelete();
        }
      },

      postDelete() {
        this.formData.stockAdjustmentItems.splice(this.deleteIndex, 1);
        this.deleteIndex = null;
        let formData = this.formData;
        this.formData = {};
        this.formData = formData;
      },

      save(event) {
        event.preventDefault()
        event.stopPropagation()

        if (!this.formData || !this.formData.stockAdjustmentItems || !this.formData.stockAdjustmentItems.length) {
          this.$notify.textError('Por favor inclua ao menos um item');
          return;
        }

        this.formData.stockAdjustmentItems.forEach(sai => {
          if (sai.quantity) {
            sai.quantity = Number(sai.quantity.toString().replace(',', '.'));
          }
        });

        this.prepareToSave();
        var successMessage = 'Ajuste salvo com sucesso';

        let promise;
        this.saving = true;
        if (this.formData.id) {
          promise = httpClient.put(`${process.env.VUE_APP_API_URL}stock-adjustments/`, this.formData);
        } else {
          promise = httpClient.post(`${process.env.VUE_APP_API_URL}stock-adjustments/`, this.formData);
        }

        promise.then(data => {
          this.saving = false;
          this.$notify.success(successMessage);
          this.$router.push('/orders/item-adjustment/edit/' + data.data.data.id);
          //this.$root.$emit('reloadOrdersCounts');
        }).catch(error => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar')
          }
        });
      },

      prepareToSave() {
        this.formData.stockLocale = this.selectedWarehouse;
        if (this.selectedWarehouse) {
          this.formData.companyGroup = this.selectedWarehouse.companyGroup;
        }

        if (!this.formData.ref || !this.formData.ref.length) {
          this.formData.ref = 'ADJUSTMENT_' + new Date().getTime();
        }

        if (!this.formData.departureDate) {
          this.formData.departureDate = new Date();
        }
      },

      getWarehouses() {
        this.loadingWarehouses = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [{
            field: 'active',
            value: true
          }]
        }).then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      getStockAdjustmentItems() {
        if (this.formData && this.formData.id) {
          this.loadingStockAdjustmentItems = true;
          httpClient
          .post(`${process.env.VUE_APP_API_URL}stock-adjustment-items/condition?page=${0}&size=${100000}`, {
            conditions: [{
              field: 'stockAdjustment.id',
              value: this.formData.id
            }]
          }).then(({ data }) => {
            this.loadingStockAdjustmentItems = false;
            this.formData.stockAdjustmentItems = data.data.content;
          })
          .catch((error) => {
            this.loadingStockAdjustmentItems = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError("Houve um erro ao buscar os itens do pedido");
            }
          });
        }
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      setItemsLabel(searchText = null) {
        if (this.items && this.items.length) {
          this.items.forEach(item => {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;

            if(searchText){
              if(item.sku && (item.sku.indexOf(searchText) >= 0)){
                item.label += ' - Ref: ' + item.sku;
              }
              if(item.gtin && (item.gtin.indexOf(searchText) >= 0)){
                item.label += ' - Cód barras: ' + item.gtin;
              }
            }
          });
        }
      },

      findAdjustment() {
        if (this.$route.params.id) {
          this.loadingAdjusmtent = true;
          httpClient.get(`${process.env.VUE_APP_API_URL}stock-adjustments/${this.$route.params.id}`)
          .then(data => {
            this.loadingAdjusmtent = false;
            this.formData = data.data.data;
            this.setAdjustmentValues();
            this.getStockAdjustmentItems();
          }).catch(error => {
            this.loadingAdjusmtent = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError("Houve um erro ao buscar o ajuste");
            }
          });
        } else {
          this.formData.movementStatus = 'L';
          this.formData.type = 'ARM';
          this.setAdjustmentValues();
        }
      },

      setAdjustmentValues() {
        this.selectedItem = this.formData.item || {}

        if (!this.formData.stockAdjustmentItems) {
          this.formData.stockAdjustmentItems = [];
        }

        if (!this.formData.stockLocale) {
          let locaStorageWarehouse = shared.getLocalStorageObj('order/warehouse');

          if (locaStorageWarehouse && locaStorageWarehouse.id) {
            this.formData.stockLocale = locaStorageWarehouse;
          }
        }

        if (this.formData.stockLocale) {
          if (!this.warehouses) {
            this.warehouses = [];
          }

          this.warehouses.push(this.formData.stockLocale);
          this.selectedWarehouse = this.formData.stockLocale;
        }
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      getItemCurrentQuantity() {
        this.loadingItemCurrentQuantity = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}stock-locales/condition/item/${this.selectedItem.id}/with-quantity?page=${0}&size=${1}`, {
          conditions: [
            {
              field: 'id',
              value: this.selectedWarehouse.id
            },
            {
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'locations.item.id',
                  value: this.selectedItem.id
                },
                {
                  joinType: 'LEFT',
                  field: 'locations.balances.itemLot.item.id',
                  value: this.selectedItem.id
                }
              ]
            },
            {
              field: 'active',
              value: true
            }
          ],
          distinct: true
        }).then(data => {
          this.loadingItemCurrentQuantity = false;
          if (data.data.data && data.data.data.content && data.data.data.content.length) {
            this.itemCurrentQuantity = data.data.data.content[0].quantity;
          } else {
            this.itemCurrentQuantity = 0;
          }
        }).catch(error => {
          this.loadingItemCurrentQuantity = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar a quantidade atual do item");
          }
        });
      },

      openOrderAttendancesModal() {
        this.$bvModal.show('order-attendances-modal');
        this.$refs.orderAttendancesModal.orderAttendancesPageChanged(1);
      },

      importFile($event) {
        if (this.$refs.importFileInput
          && this.$refs.importFileInput.files
          && this.$refs.importFileInput.files.length) {

          let reader = new FileReader();
          let f = this.$refs.importFileInput.files[0];

          this.saving = true;
          reader.onload = ((theFile) => {
            return (e) => {
              try {
                this.importedFile = e.target.result;
                this.$bvModal.show('file-import-confirmation-modal');
              } catch (e) {
                this.saving = false;
                console.error(e);
                this.$notify.textError('Houve um erro ao importar o arquivo');
              }
            }
          })(f);

          reader.onerror = (error) => {
            this.saving = false;
            console.error(error);
            this.$notify.textError('Houve um erro ao importar o arquivo');
          }

          reader.readAsBinaryString(f);
        }
      },

      startOrder(type) {
        this.saving = true;
        return httpClient
        .post(
          `${
            process.env.VUE_APP_API_URL
          }orders/condition?page=${0}&size=${1}`, {
            conditions: [
              {
                field: "stockAdjustmentId",
                value: this.formData.id,
              },
              {
                field: "fiscalOperation.type",
                value: type,
              },
            ],
          }
        )
        .then(({ data }) => {
          this.saving = false;

          if (
            data &&
            data.data &&
            data.data.content &&
            data.data.content.length
          ) {
            if (data.data.content[0].movementStatus === 'L') {
              this.$router.push('/orders/item-' + (type === 'ENT' ? 'in' : 'out') + '/start/' + data.data.content[0].id);
            } else {
              this.$notify.textError("O pedido de " + (type === 'ENT' ? 'entrada' : 'saída') + " já foi finalizado.");
            }
          } else {
            this.$notify.textError("Este ajuste não possui pedido de " + (type === 'ENT' ? 'entrada' : 'saída') + " ou os pedidos ainda não foram gerados. Tente novamente mais tarde.");
          }
        })
        .catch((error) => {
          this.saving = false;

          if (error.message) {
            this.$notify.error(error);
          }
        });
      },

      openAutoMovementsConfirmationModal() {
        this.$bvModal.show('create-movements-automatically-confirmation-modal');
      },

      createMovementsAutomatically() {
        this.saving = true;
        return httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-adjustments/automatic-movements`, [ this.formData.id ])
        .then(({ data }) => {
          this.saving = false;
          this.$notify.success('Os movimentos estão sendo criados. Acompanhe o progresso na lista de ajustes ou no formulário.');
          this.$router.push('/orders/item-adjustment/all');
        })
        .catch((error) => {
          this.saving = false;

          if (error.message) {
            this.$notify.error(error);
          }
        });
      },

      confirmFileImport() {
        this.$bvModal.hide('file-import-confirmation-modal');
        this.saving = true;
        return httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-adjustments/import/file?itemsPerStockAdjustment=${this.itemsPerStockAdjustment}`, this.importedFile)
        .then((data) => {
          if (data.data.data.length === 1) {
            this.saving = false;
            this.$notify.success('Arquivo importado com sucesso');
            this.$router.push('/orders/item-adjustment/edit/' + data.data.data[0].id);
            //this.$root.$emit('reloadOrdersCounts');
          } else {
            let text = '';

            for (let i = 0; i < data.data.data.length; i++) {
              if (i === data.data.data.length - 1) {
                text += ' e ';
              } else if (i > 0) {
                text += ', ';
              }

              text += data.data.data[i].number;
            }

            this.$notify.success('Criados os pedidos ' + text);
            this.$router.push('/orders/item-adjustment/all');
            //this.$root.$emit('reloadOrdersCounts');
          }
        })
        .catch((error) => {
          this.saving = false;

          if (error.message) {
            this.$notify.error(error);
          }
        });
      },

      openAutoFillConfirmationModal() {
        this.$bvModal.show('auto-fill-confirmation-modal');
      },

      confirmAutoFill() {
        this.$bvModal.hide('auto-fill-confirmation-modal');
        this.$router.push(`/orders/item-adjustment/auto-fill-revision/${this.formData.id}`);
      },

      setIsUserManager() {
        if (
          this.authUser &&
          this.authUser.profile &&
          this.authUser.profile.modules
        ) {
          let adjustmentsProfileModule = this.authUser.profile.modules.find(
            (pm) => {
              return (
                pm.name && pm.name.toString().toUpperCase() == "ADJUSTMENTS"
              );
            }
          );

          this.isUserManager =
            adjustmentsProfileModule && adjustmentsProfileModule.canExecute;
        }
      },

      openDeleteItemsWithoutMovementConfirmationModal() {
        this.$bvModal.show('delete-items-without-movements-confirmation-modal');
      },

      deleteItemsWithoutMovement() {
        this.saving = true;
        httpClient.delete(`${process.env.VUE_APP_API_URL}stock-adjustment-items/without-movement`, this.formData.id)
        .then(data => {
          this.deleteItemsWithoutMovementsMessage = 'Itens excluídos com sucesso.';

          if (data.data.data.movementStatus === 'O') {
            this.deleteItemsWithoutMovementsMessage += ' O ajuste ' + this.formData.number + ' foi finalizado.';
          }

          this.$bvModal.show('post-delete-items-without-movements-modal');
        }).catch(error => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao excluír')
          }
        });
      },

      reloadPage() {
        this.$bvModal.hide('post-delete-items-without-movements-modal');
        this.$router.go();
      },

      verifyDiscrepancies() {
        this.prepareToSave();
        this.loadingStockAdjustmentItems = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}stock-adjustments/discrepancy/verification/${this.verifyDiscrepancyLimit}`, this.formData)
        .then(data => {
          this.loadingStockAdjustmentItems = false;

          if (data.data.data.id) {
            this.$router.push('/orders/item-adjustment/edit/' + data.data.data.id);
          } else {
            this.$notify.success('Nenhuma discrepância encontrada');
          }
        }).catch(error => {
          this.loadingStockAdjustmentItems = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Ocorreu um erro')
          }
        });
      }
    }
  }

</script>

<style scoped>
</style>
